<template>
	<div id="">
		<el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
			<h3 class="loginTitle">系统登录</h3>
			<el-form-item prop="account">
				<el-input prefix-icon="el-icon-user" type="text" auto-complete="false" v-model="loginForm.account" placeholder="请输入用户名" clearable></el-input>
			</el-form-item>
			<el-form-item prop="password">
				<el-input prefix-icon="el-icon-lock" type="password" auto-complete="false" v-model="loginForm.password" placeholder="请输入密码" clearable></el-input>
			</el-form-item>
			<!-- <el-checkbox v-model="checked" class="loginCheck">记住我</el-checkbox> -->
			<el-button type="primary" style="width: 100%;" @click="submitLogin">登录</el-button>
		</el-form>
	</div>
</template>

<script>
	export default {
		name:"Login",
		data(){
			return{
				loginForm:{
					account:'',
					password:''
				},
				checked:true,
				rules:{
					account:[{required: true, message: '请输入用户名', trigger: 'blur'}],
					password:[{required: true, message: '请输入密码', trigger: 'blur'}]
				}
			}
		},
		methods:{
			submitLogin(){
				let parm = new URLSearchParams()
				parm.append('USERID',this.loginForm.account);
				parm.append('PASSWORD',this.loginForm.password);
				this.$refs.loginForm.validate((valid) => {
				  if (valid) {
					this.$axios.post('/login.php',parm).then(res=>{
						// console.log(res)
						if(res.data.code == 200){
							// console.log(res)
							this.$message.success(res.data.msg);
							// 保存TOKEN
							let data={
								token:res.data.data.TOKEN,
								username:res.data.data.USERNAME,
								ID:res.data.data.ID,
								GRADE:res.data.data.GRADE
							}
							// console.log(data)
							window.sessionStorage.setItem('userSession',JSON.stringify(data));
							// window.sessionStorage.setItem('username',res.data.username)
							//成功跳转
							this.$router.push('/Home')
							
						} else {
							this.$message.error(res.data.msg);
						}
					})
				  } else {
					// this.$message.error('请填写所有字段！');
					return false;
				  }
				});
			}
		}
	}
</script>

<style scoped>
	.loginContainer{
		/* border-radius: 15px; */
		background-clip: padding-box;
		margin: 180px auto;
		width: 350px;
		padding: 15px 35px 15px 35px;
		background: #fff;
		border: 1px solid #f0f0f0;
		box-shadow: 0 0 25px #cac6c6;
	}
	.loginTitle{
		font-family: 微软雅黑,"microsoft yahei";
		margin: 0 auto 40px auto;
		text-align: center;
	}
	.loginCheck{
		margin:  0 8px 15px 0;
	}
</style>
